<template>
  <section class="orders-header">
    <h3 class="titlePage titlePage_textLeft">
      Select Color
      <span class="title__img"
            @click="back"
      >
        <svg-icon name="arrow-left" class=""/>
      </span>
    </h3>

    <div class="row"
         v-for="(item, key, index) in colors"
    >
      <div class="col-12">
        <h2 class="titlePage_subtitle titlePage">{{key}}</h2>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-3 col-6"
           v-for="color in item.colours"
           :key="color.id"
           @click="selectColors(color)"
      >
        <div class="box">
          <img class="box__img" :src="color.image" alt="color.colour_name" v-if="color.image">
          <p v-else-if="color.hexcode">
            <svg-icon name="palette" class="large_icon box__img" :style="{color: color.hexcode}"/>
          </p>
          <p v-if="!color.image && !color.hexcode">
            <svg-icon name="palette" class="large_icon box__img"/>
          </p>
          <p class="text_default">{{color.colour_name}}{{color.id}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  export default {
    name: "ApplyColor",

    data(){
      return {

      }
    },

    computed: {
      ...mapState('applySet', ['idToColor']),
      ...mapGetters({
        colors: 'colors/colors'
      })
    },

    methods: {
      selectColors(color){
        this.$store.commit('applySet/setColor', color.id);
        this.$parent.color = false;
        this.$parent.locations = true;
      },

      back(){
        this.$parent.color = false;
        this.$parent.package = true;
      }
    },

    created(){
      this.$store.dispatch('colors/getColorsNew', this.idToColor);
    }
  }
</script>

<style scoped lang="scss">
  .box{
    margin-bottom: 10px;
    text-align: center;
  }

  .titlePage{
    margin-bottom: 10px;
  }

  .title {
    &Page {
      position: relative;
    }
    &__img {
      right: 0;
      left: auto;
    }
  }

  .box {
    margin-bottom: 1rem;
    &__img {
      position: static;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 100%;
      max-width: 160px;
      height: 100px;
    }
    p {
      text-align: center;
    }
  }
</style>
